<div class="form-container m-t-24">

      <!-- Profile Form Starts -->
      <form action="" [formGroup]="profileFormGroup" (ngSubmit)="submit()">
        <!-- <mat-dialog-content id="scrollbar"> -->
          <div class="row m-r-0">
            <!-- Profile Picture -->
            <div class="col-12 m-b-10 d-flex align-items-center">
              <div class="col-4 col-md-3">
                <app-label text="Profile Picture" for="profilePicture" [required]="true"></app-label>
              </div>
              <div class="col-8 col-md-9">
                <input type="file" accept="image/*" id="profilePicture" name="selectedUserProfile" 
                        (change)="profilePicture($event)" hidden>
                <label for="profilePicture">
                  <img *ngIf="digitalSignImageUrl" class="profileimage w-10" [src]="digitalSignImageUrl">
                  <img *ngIf="!digitalSignImageUrl" mat-card-image [src]="selectedImage" alt="Upload Icon">
                </label>
                <!-- <div *ngIf="fileTypeError" class="f-w-600 mandatory">{{ errorMessage }}</div>
                <div *ngIf="!fileTypeError && selectedFileName" class="file-info">
                  <span>File Name: {{ selectedFileName }}</span>
                  <span>File Size: {{ selectedFileSize }}</span>
                </div> -->
                <!-- <mat-icon *ngIf="digitalSignImageUrl" (click)="removeImage()">close</mat-icon> -->
              </div>
            </div>
            
            <!-- User Name -->
            <div class="col-12 col-md-6 mb-3">
              <app-label text="User Name" for="Username" className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 place">
                <input type="text" matInput formControlName="Username"  placeholder="Enter Your User Name" required>
                <mat-error *ngIf="checkError('Username', 'required')">User Name is required</mat-error>
              </mat-form-field>
            </div>
  
            <div class="col-md-"></div>
  
            <!-- First Name -->
            <div class="col-12 col-md-6 mb-3">
              <app-label text="First Name" for="Firstname" className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 place">
                <input type="text" matInput formControlName="Firstname" placeholder="Enter Your First Name" required>
                <mat-error *ngIf="checkError('Firstname', 'required')">First Name is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Last Name -->
            <div class="col-12 col-md-6 mb-3">
              <app-label text="Last Name" for="Lastname" className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 place">
                <input type="text" matInput formControlName="lastname" placeholder="Enter Your Last Name" required>
                <mat-error *ngIf="checkError('lastname', 'required')">Last Name is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Date of Birth -->
            <div class="col-12 col-md-6 mb-3">
              <app-label text="Date of Birth" for="DateOfBirth"  className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 place">
                <input matInput [matDatepicker]="picker" formControlName="dob" placeholder="Select DOB"  required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="checkError('dob', 'required')">Date of Birth is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Gender -->
            <div class="col-12 col-md-6 mb-3">
              <app-label text="Gender" for="Gender" className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 place">
                <mat-select formControlName="gender" required placeholder="Gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('gender', 'required')">Gender is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Email -->
            <div class="col-12 col-md-6 mb-3">
              <app-label text="Email" for="Email" className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 place">
                <input type="email" matInput formControlName="email" placeholder="Ex. xyz@ivin.com" required>
                <mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error>
                <mat-error *ngIf="checkError('email', 'email')">Invalid email address</mat-error>
              </mat-form-field>
            </div>

              <!-- AGE -->
              <div class="col-12 col-md-2 mb-3">
                <app-label text="Age" for="age" className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 place">
                  <input type="number" matInput formControlName="age" placeholder="Enter Your Age" required>
                  <mat-error *ngIf="checkError('age', 'required')">Age is required</mat-error>
                </mat-form-field>
              </div>
    
              <!-- Country Code -->
              <div class="col-12 col-md-4 mb-3">
                <app-label text="Country Code" for="CountryCode" className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 place">
                  <mat-select formControlName="Countrycode" required placeholder="Select your Country Code">
                    <mat-option value="+91(IND)">+91 (IND)</mat-option>
                    <mat-option value="+1(USA)" selected="true">+1 (USA)</mat-option>
                  </mat-select>
                  <mat-error *ngIf="checkError('Countrycode', 'required')">Country Code is required</mat-error>
                </mat-form-field>
              </div>
    
              <!-- Phone Number -->
              <div class="col-12 col-md-6 mb-3">
                <app-label text="Phone Number" for="PhoneNumber"  className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 place">
                  <input matInput type="number" formControlName="phoneNumber" placeholder="Enter Your Phone Number" required>
                  <mat-error *ngIf="checkError('phoneNumber', 'required')">Phone Number is required</mat-error>
                </mat-form-field>
              </div>
    
              <!-- Address -->
              <div class="col-12 col-md-12 mb-3">
                <app-label text="Address" for="Address" className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 custom-placeholder">
                  <textarea matInput formControlName="address" placeholder="Enter Your Address" required></textarea>
                  <mat-error *ngIf="checkError('address', 'required')">Address is required</mat-error>
                </mat-form-field>
              </div>
    
              <!-- Country -->
              <div class="col-12 col-md-6 mb-3">
                <app-label text="Country" for="Country"  className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 place">
                  <mat-select formControlName="Country" required placeholder="Country">
                    <mat-option value="India">India</mat-option>
                  </mat-select>
                  <mat-error *ngIf="checkError('Country', 'required')">Country is required</mat-error>
                </mat-form-field>
              </div>
    
              <!-- State -->
              <div class="col-12 col-md-6 mb-3">
                <app-label text="State" for="State" className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 place">
                  <mat-select formControlName="state" required placeholder="State">
                    <mat-option *ngFor="let state of allstates" [value]="state">{{state}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="checkError('state', 'required')">State is required</mat-error>
                </mat-form-field>
              </div>
    
              <!-- City -->
              <div class="col-12 col-md-6 mb-3">
                <app-label text="City" for="City" className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 place">
                  <input matInput formControlName="city" placeholder="Enter Your City" required>
                  <mat-error *ngIf="checkError('city', 'required')">City is required</mat-error>
                </mat-form-field>
              </div>
    
              <!-- Pincode -->
              <div class="col-12 col-md-6 mb-3">
                <app-label text="PIN Code" for="Pincode"  className="m-l-8" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 place">
                  <input matInput type="number" formControlName="pincode" placeholder="Enter Your Pincode" required>
                  <mat-error *ngIf="checkError('pincode', 'required')">Pincode is required</mat-error>
                </mat-form-field>
              </div>
                        
               <!-- Short Description -->
               <!-- <div class="col-12 mb-3">
                <app-label text="Short Description" for="ShortDescription" [required]="true"></app-label>
                <mat-form-field appearance="outline" class="w-100 custom-placeholder">
                  <textarea matInput formControlName="shortdescription" placeholder="Type your Description" required></textarea>
                  <mat-error *ngIf="checkError('shortdescription', 'required')">Short Description is required</mat-error>
                </mat-form-field>
              </div> -->
    
              <!-- About Yourself -->
              <div class="col-12 mb-3">
              <app-label text="Short Description" for="ShortDescription" className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 custom-placeholder">
                <textarea matInput formControlName="shortdescription" placeholder="Type your Description" required></textarea>
                <mat-error *ngIf="checkError('shortdescription', 'required')">Short Description is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- About Yourself -->
            <div class="col-12 mb-3">
              <app-label text="Brief About Yourself" for="AboutYourSelf" className="m-l-8" [required]="true"></app-label>
              <mat-form-field appearance="outline" class="w-100 custom-placeholder">
                <textarea matInput class="custom-textarea" formControlName="aboutyourself" placeholder="Type Brief About Yourself" required></textarea>
                <mat-error *ngIf="checkError('aboutyourself', 'required')">Brief About Yourself is required</mat-error>
              </mat-form-field>
            </div>
            
          </div>
  
        <!-- </mat-dialog-content> -->
  
        <!-- Action Buttons -->
        <div align="center">
          <button mat-raised-button class="blackbutton" type="submit">Save</button>
          <button mat-button mat-dialog-close class="m-l-20 cancel-border" type="button">Cancel</button>
        </div>
      </form>

</div>    